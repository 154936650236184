// @flow

import React from "react";
import {
    AssignmentLayout,
    Layout,
    Reports as _Reports,
    withPrivateRoute,
} from "@containers";

/**
 * Verslagen
 */
const Reports = (props: *) => (
    <Layout {...props}>
        <AssignmentLayout title="Verslagen">
            <_Reports />
        </AssignmentLayout>
    </Layout>
);

export default withPrivateRoute(Reports, "/opdrachten/:inspectionId/verslagen");
